"use client";
import { ptBR } from "@/i18n/pt-br";
import { ConversaType } from "@/types/ConversaTypes";
import { MensagemType } from "@/types/MensagemTypes";
import { getCookie } from "cookies-next";
import { format, isToday, parse } from "date-fns";
import { redirect } from "next/navigation";
import { CookieEnum, TipoMensagemEnum } from "./EnumUtils";

const i18n = ptBR;
export const isNullOrEmpty = (value: string | null | undefined) => {
    return value === null || value === undefined || value.trim() === "";
};

export const redirectToLogin = () => {
    redirect("/login");
};

// Recebe um campo de data de um campo (String, Date, Null ou Undefined) e retorna um Date
export const retornarValorEmData = (data: unknown): Date => {
    //Quando não houver data, jogar mensagem no passado
    if (!data) return new Date(0);

    if (data instanceof Date) return data;

    try {
        return parse(data as string, "dd/MM/yyyy HH:mm:ss.SSS", new Date());
    } catch {
        return new Date(0);
    }
};

export const ordenarMensagensPorData = (mensagens: MensagemType[]): MensagemType[] => {
    if (mensagens.length === 0) {
        return mensagens;
    }

    // Pré processa as datas das mensagens para otimizar desempenho na ordenação.
    const mensagensComDatas = mensagens.map((mensagem) => ({
        ...mensagem,
        _data: retornarValorEmData(mensagem.dataHoraEnvio),
    }));

    return [...mensagensComDatas]
        .sort((a, b) => a._data.getTime() - b._data.getTime()) // Ordena por data
        .map(({ _data, ...mensagem }) => mensagem); // Remove a propriedade _data e retorna as mensagens originais ordenadas
};

export const agruparMensagensPorData = (mensagens: MensagemType[]): Map<string, MensagemType[]> => {
    const grupos = new Map<string, MensagemType[]>();
    mensagens.forEach((mensagem) => {
        const formatoDataHora = "dd/MM/yyyy HH:mm:ss.SSS";
        const data = parse(mensagem.dataHoraEnvio.toString(), formatoDataHora, new Date());
        const dataFormatada = isToday(data) ? "Hoje" : format(data, "dd/MM/yyyy");
        const horaFormatada = format(data, "HH:mm");

        if (!grupos.has(dataFormatada)) {
            grupos.set(dataFormatada, []);
        }
        grupos.get(dataFormatada)?.push({ ...mensagem, horaEnvio: horaFormatada });
    });
    return grupos;
};

export const ordenarConversasPorData = (conversas: ConversaType[]): ConversaType[] => {
    if (!conversas || conversas.length === 0) {
        return conversas;
    }

    return conversas.sort((a, b) => {
        const formatoDataHora = "dd/MM/yyyy HH:mm:ss.SSS";
        const dataHoraEnvioA = a.ultimaMensagem
            ? parse(a.ultimaMensagem.dataHoraEnvio.toString(), formatoDataHora, new Date())
            : new Date(0);
        const dataHoraEnvioB = b.ultimaMensagem
            ? parse(b.ultimaMensagem.dataHoraEnvio.toString(), formatoDataHora, new Date())
            : new Date(0);
        if (dataHoraEnvioA > dataHoraEnvioB) {
            return -1;
        } else {
            return 1;
        }
    });
};

export const adicionaOuAlteraConversaLista = (conversa: ConversaType, conversas: ConversaType[]): ConversaType[] => {
    const index = conversas.findIndex((item) => item.codConversa === conversa.codConversa);
    if (index > -1) {
        return ordenarConversasPorData([...conversas.slice(0, index), conversa, ...conversas.slice(index + 1)]);
    }
    return ordenarConversasPorData([...conversas, conversa]);
};

export const converteArquivoParaBase64 = async (arquivo: File): Promise<string> => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onload = (event: ProgressEvent<FileReader>) => {
            if (typeof event.target?.result === "string") {
                const regex = /^data:(?:\w+\/[\w+]+);base64,/;
                const base64String = event.target.result.replace(regex, "");
                resolve(base64String);
            } else {
                reject(new Error("Não foi possível converter o arquivo para base64."));
            }
        };

        reader.readAsDataURL(arquivo);
    });
};

export const determinarTipoDeArquivo = (arquivo: File): TipoMensagemEnum => {
    if (arquivo.type.startsWith("image/")) {
        return TipoMensagemEnum.IMAGE;
    } else if (arquivo.type.startsWith("video/")) {
        return TipoMensagemEnum.VIDEO;
    } else if (arquivo.type.startsWith("audio/")) {
        return TipoMensagemEnum.AUDIO;
    } else {
        return TipoMensagemEnum.DOCUMENT;
    }
};

export const retornaExtensaoNomeArquivo = (nomeArquivo: string | undefined): string => {
    if (!nomeArquivo) {
        return "";
    }
    const stringExtensao = nomeArquivo.lastIndexOf(".");
    if (stringExtensao === -1) {
        return "";
    }
    return nomeArquivo.substring(stringExtensao + 1);
};

export const gerarStringAleatoria = (tamanho: number): string => {
    let result = "";
    const characters = "0123456789";

    for (let i = 0; i < tamanho; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }

    return result;
};

export const converterBlobParaBase64 = async (blob: Blob): Promise<string> => {
    const arrayBuffer = await blob.arrayBuffer();
    const buffer = Buffer.from(arrayBuffer);
    return buffer.toString("base64");
};

export const unirArrayConversaSemDuplicadas = (array1: ConversaType[], array2: ConversaType[]): ConversaType[] => {
    const arrayUniao = [...array1, ...array2];
    const arrayUniaoSemDuplicadas = arrayUniao.filter((item, index) => {
        return arrayUniao.findIndex((item2) => item2.codConversa === item.codConversa) === index;
    });
    return arrayUniaoSemDuplicadas;
};

export const retornarSetoresUsuario = () => {
    const setores = getCookie(CookieEnum.SETORES_USUARIO)?.toString() || "";
    return new Set(setores.replace(/\s+/g, "").toLowerCase().split(","));
};

export const retornarLoginUsuarioDoCookie = () => getCookie(CookieEnum.LOGIN_USUARIO);
