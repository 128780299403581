import { retornarListaConversasPesquisa } from "@/api/chatAPI";
import { ConversaType } from "@/types/ConversaTypes";
import { MenuLateralEnum, MenuLateralType } from "@/types/MenuTypes";
import { UsuarioType } from "@/types/UsuarioTypes";
import { ordenarConversasPorData, retornarLoginUsuarioDoCookie, retornarSetoresUsuario } from "@/util/ChatUtils";
import { create } from "zustand";

interface ListagemLateralState {
    menuLateral: MenuLateralType;
    conversas: ConversaType[];
    conversasFiltradas: ConversaType[];
    apenasNaoAssociadas: boolean;
    paginaListagem: number;
    tamanhoListagem: number;
    totalAbertasNaoLidas: number;
    totalSetoresNaoLidas: number;
    totalMinhasNaoLidas: number;
}

interface ListagemLateralActions {
    alterarMenuLateral: (menu: MenuLateralType) => void;
    adicionarListaConversas: (
        listaConversas: ConversaType[],
        usuario: UsuarioType | null,
        menuLateral: MenuLateralType
    ) => void;
    removerConversa: (conversaRemovida: ConversaType) => void;
    adicionaOuAlterarConversa: (conversaAlterada: ConversaType) => void;
    pesquisarConversas: (texto: string) => void;
    alterarStatusApenasNaoAssociadas: (statusApenasNaoAssociadas: boolean) => void;
    alterarPaginaListagem: (pagina: number) => void;
    iniciarTotalizadores: (
        totalAbertasNaoLidas: number,
        totalSetoresNaoLidas: number,
        totalMinhasNaoLidas: number
    ) => void;
    atualizarTotalizadores: VoidFunction;
}

export const useListagemLateralStore = create<ListagemLateralState & ListagemLateralActions>((set, get) => ({
    conversas: [],
    conversasFiltradas: [],
    menuLateral: MenuLateralEnum.CONVERSAS_ABERTAS,
    apenasNaoAssociadas: true,
    paginaListagem: 0,
    tamanhoListagem: 120,
    totalAbertasNaoLidas: 0,
    totalSetoresNaoLidas: 0,
    totalMinhasNaoLidas: 0,

    alterarMenuLateral: (menu: MenuLateralType) => {
        set((state) => (state.menuLateral !== menu ? { menuLateral: menu } : state));
    },

    adicionarListaConversas: (
        listaConversas: ConversaType[],
        usuario: UsuarioType | null,
        menuLateral: MenuLateralType
    ) => {
        const setoresUsuario = usuario?.setores.replace(/\s+/g, "").split(",") || [];
        const { apenasNaoAssociadas } = get();

        let conversasFiltro = apenasNaoAssociadas
            ? listaConversas.filter((c) => !c.setor && !c.usuarioResponsavel)
            : [...listaConversas];

        if (menuLateral === MenuLateralEnum.MEUS_SETORES) {
            conversasFiltro = conversasFiltro.filter((c) => c.setor && setoresUsuario.includes(c.setor));
        } else if (menuLateral === MenuLateralEnum.MINHAS_CONVERSAS) {
            conversasFiltro = conversasFiltro.filter((c) => c.usuarioResponsavel?.login === usuario?.login);
        }

        set({
            conversas: ordenarConversasPorData(listaConversas),
            conversasFiltradas: conversasFiltro,
        });
    },
    adicionaOuAlterarConversa: (conversaAlterada: ConversaType) => {
        set((state) => {
            const conversas = [...state.conversas];
            const index = conversas.findIndex((c) => c.codConversa === conversaAlterada.codConversa);

            if (index !== -1) {
                conversas[index] = conversaAlterada;
            } else {
                conversas.push(conversaAlterada);
            }

            return { conversas: ordenarConversasPorData(conversas) };
        });
    },

    removerConversa: (conversaRemovida: ConversaType) => {
        set((state) => ({
            conversas: state.conversas?.filter((conversa) => conversa.codConversa !== conversaRemovida.codConversa),
            conversasFiltradas: state.conversasFiltradas?.filter(
                (conversa) => conversa.codConversa !== conversaRemovida.codConversa
            ),
        }));
    },

    pesquisarConversas: (texto: string) => {
        const { conversas, apenasNaoAssociadas, menuLateral } = get();
        if (texto.length >= 3) {
            retornarListaConversasPesquisa(texto)
                .then((conversasPesquisadas) => {
                    set((state) => ({
                        conversasFiltradas: ordenarConversasPorData(conversasPesquisadas.content),
                    }));
                })
                .catch((err) => {
                    console.error("Erro ao pesquisar conversas:", err);
                });
        } else if (apenasNaoAssociadas) {
            set((state) => ({
                conversasFiltradas: conversas?.filter((conversa) => !conversa.setor && !conversa.usuarioResponsavel),
            }));
        } else {
            const setoresUsuario = retornarSetoresUsuario();
            const loginUsuario = retornarLoginUsuarioDoCookie();
            let conversasFiltro = conversas;

            if (menuLateral === MenuLateralEnum.MEUS_SETORES) {
                conversasFiltro = conversasFiltro?.filter(
                    (conversa) => conversa.setor && setoresUsuario?.has(conversa.setor.toLowerCase())
                );
            } else if (menuLateral === MenuLateralEnum.MINHAS_CONVERSAS) {
                conversasFiltro = conversasFiltro?.filter(
                    (conversa) => conversa.usuarioResponsavel && conversa.usuarioResponsavel.login === loginUsuario
                );
            }
            set((state) => ({
                conversasFiltradas: conversasFiltro,
            }));
        }
    },
    alterarStatusApenasNaoAssociadas: (statusApenasNaoAssociadas: boolean) => {
        const { conversas, menuLateral } = get();

        if (statusApenasNaoAssociadas) {
            set((state) => ({
                apenasNaoAssociadas: statusApenasNaoAssociadas,
                conversasFiltradas: conversas?.filter((conversa) => !conversa.setor && !conversa.usuarioResponsavel),
            }));
        } else {
            const setoresUsuario = retornarSetoresUsuario();
            const loginUsuario = retornarLoginUsuarioDoCookie();
            let conversasFiltro = conversas;

            if (menuLateral === MenuLateralEnum.MEUS_SETORES) {
                conversasFiltro = conversasFiltro?.filter(
                    (conversa) => conversa.setor && setoresUsuario?.has(conversa.setor.toLowerCase())
                );
            } else if (menuLateral === MenuLateralEnum.MINHAS_CONVERSAS) {
                conversasFiltro = conversasFiltro?.filter(
                    (conversa) => conversa.usuarioResponsavel && conversa.usuarioResponsavel.login === loginUsuario
                );
            }
            set((state) => ({
                apenasNaoAssociadas: statusApenasNaoAssociadas,
                conversasFiltradas: conversasFiltro,
            }));
        }
    },

    alterarPaginaListagem: (pagina: number) => {
        set((state) => ({
            paginaListagem: pagina,
        }));
    },

    iniciarTotalizadores: (totalAbertasNaoLidas: number, totalSetoresNaoLidas: number, totalMinhasNaoLidas: number) => {
        set((state) => ({
            totalAbertasNaoLidas: totalAbertasNaoLidas,
            totalSetoresNaoLidas: totalSetoresNaoLidas,
            totalMinhasNaoLidas: totalMinhasNaoLidas,
        }));
    },

    atualizarTotalizadores: () => {
        const { conversas } = get();
        const setoresUsuario = retornarSetoresUsuario();
        const loginUsuario = retornarLoginUsuarioDoCookie();

        const { abertasNaoLidas, setoresNaoLidas, minhasNaoLidas } = conversas.reduce(
            (contador, conversa) => {
                if (!conversa.naoLida) return contador;
                contador.abertasNaoLidas++;
                if (conversa.usuarioResponsavel && conversa.usuarioResponsavel.login === loginUsuario)
                    contador.minhasNaoLidas++;
                if (conversa.setor && setoresUsuario?.has(conversa.setor.toLowerCase())) contador.setoresNaoLidas++;
                return contador;
            },
            { abertasNaoLidas: 0, setoresNaoLidas: 0, minhasNaoLidas: 0 }
        );

        set((state) => ({
            totalAbertasNaoLidas: abertasNaoLidas,
            totalSetoresNaoLidas: setoresNaoLidas,
            totalMinhasNaoLidas: minhasNaoLidas,
        }));
    },
}));
