"use client";
import { BotaoExcluir } from "@/components/botoes/Botoes";
import BotaoGravacaoAudio, { ReprodutorAudio } from "@/components/botoes/BotoesAudio";
import ListagemTemplates from "@/components/templates/ListagemTemplates";
import { ptBR } from "@/i18n/pt-br";
import { useAudioStore } from "@/store/audioStore";
import { useConversaStore } from "@/store/conversaStore";
import { ArquivoRespostaType } from "@/types/ArquivoTypes";
import { MensagemEnvioType, MensagemType } from "@/types/MensagemTypes";
import { determinarTipoDeArquivo, isNullOrEmpty } from "@/util/ChatUtils";
import { CookieEnum, TipoMensagemEnum } from "@/util/EnumUtils";
import { faBolt, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCookie } from "cookies-next";
import { useRouter } from "next/navigation";
import { useCallback, useEffect, useRef, useState } from "react";
import CabecalhoMensagemResposta from "./mensagem/CabecalhoMensagemResposta";
import { processarArquivo, processarAudio, tratarErro } from "./mensagem/FuncoesMensagem";

const i18n = ptBR;

interface FormMensagemProps {
    mensagemResposta?: MensagemType;
    setMensagemResposta: React.Dispatch<React.SetStateAction<MensagemType | undefined>>;
}
const FormMensagem = ({ mensagemResposta, setMensagemResposta }: FormMensagemProps) => {
    const [textoMensagem, setTextoMensagem] = useState<string>("");
    const [arquivoNovaMensagem, setArquivoNovaMensagem] = useState<FileList | null>(null);
    const [exibeListagemTemplates, setExibeListagemTemplates] = useState<boolean>(false);
    const { conversa, stompClient, isConversaInativa, marcarConversaComoLida } = useConversaStore();
    const { audioEnvio, removerAudioEnvio } = useAudioStore();
    const inputUpload = useRef<HTMLInputElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const formRef = useRef<HTMLFormElement>(null);
    const router = useRouter();

    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = "auto";
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    }, [textoMensagem]);

    const isMensagemVazia = useCallback(() => {
        const arquivos = inputUpload.current?.files;
        const isMensagemTextoVazia = isNullOrEmpty(textoMensagem);
        const isArquivoVazio = arquivos?.length === 0;
        return isMensagemTextoVazia && isArquivoVazio && !audioEnvio;
    }, [textoMensagem, audioEnvio]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const usuarioResponsavel = getCookie(CookieEnum.LOGIN_USUARIO);
            if (!conversa || !stompClient) return;

            if (!usuarioResponsavel) {
                router.push("/login");
                return;
            }

            if (isMensagemVazia()) return;

            const arquivos = inputUpload.current?.files;
            if (arquivos && arquivos.length > 0) {
                //Envio da mensagem  de texto e depois dos arquivos.
                await enviarMensagem(usuarioResponsavel as string, null);
                for (const arquivo of arquivos) {
                    await enviarMensagem(usuarioResponsavel as string, arquivo);
                }
            } else {
                await enviarMensagem(usuarioResponsavel as string, null);
            }

            limparFormulario();
        } catch (erro) {
            tratarErro(erro, "erro-enviar-mensagem");
        }
    };

    const clickListagemTemplates = async () => {
        setExibeListagemTemplates(!exibeListagemTemplates);
    };

    const enviarMensagem = async (usuarioResponsavel: string, arquivo: File | null) => {
        if (!conversa || !stompClient) return;
        const arquivoSalvo = await processarArquivoOuAudio(arquivo);

        const mensagemEnvio = criarMensagemEnvio(usuarioResponsavel, arquivoSalvo.tipoMensagem, arquivoSalvo.arquivo);
        if (!mensagemEnvio) return;

        if (mensagemResposta) {
            mensagemEnvio.codMensagemResposta = mensagemResposta.codMensagem;
        }

        stompClient.publish({
            destination: `/chat/${conversa.codConversa}/mensagem`,
            body: JSON.stringify(mensagemEnvio),
            skipContentLengthHeader: true,
        });
    };

    const processarArquivoOuAudio = async (
        arquivo: File | null
    ): Promise<{ tipoMensagem: TipoMensagemEnum; arquivo: ArquivoRespostaType | null }> => {
        if (arquivo) {
            const arquivoSalvo = await processarArquivo(arquivo);
            if (!arquivoSalvo) {
                throw Error(i18n.err_salvar_arquivo);
            }
            return { tipoMensagem: determinarTipoDeArquivo(arquivo), arquivo: arquivoSalvo };
        } else if (audioEnvio) {
            const arquivoSalvo = await processarAudio(audioEnvio);
            if (!arquivoSalvo) {
                throw Error(i18n.err_enviar_audio);
            }
            removerAudioEnvio();
            return { tipoMensagem: TipoMensagemEnum.AUDIO, arquivo: arquivoSalvo };
        }
        return { tipoMensagem: TipoMensagemEnum.TEXT, arquivo: null };
    };

    const criarMensagemEnvio = useCallback(
        (
            usuarioResponsavel: string | undefined,
            tipoMensagem: TipoMensagemEnum,
            arquivoSalvo: ArquivoRespostaType | null
        ): MensagemEnvioType | null => {
            if (!conversa) return null;

            return {
                codConversa: conversa.codConversa,
                remetente: usuarioResponsavel?.toString() ?? i18n.npr_usuario_padrao,
                tipo: tipoMensagem,
                telContato: conversa.telContato,
                nomeContato: conversa.nomeContato,
                texto: arquivoSalvo?.urlPublica ? "" : textoMensagem,
                ...(arquivoSalvo && { arquivo: arquivoSalvo }),
            };
        },
        [conversa, textoMensagem]
    );

    const handleUploadArquivo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setArquivoNovaMensagem(event.target.files ? event.target.files : null);
    };

    const handleClickUpload = useCallback(() => {
        inputUpload.current?.click();
    }, []);

    const removerArquivo = useCallback(() => {
        if (inputUpload.current) {
            inputUpload.current.value = "";
        }
        setArquivoNovaMensagem(null);
    }, []);

    const cancelarRespostaMensagem = useCallback(() => {
        setMensagemResposta(undefined);
    }, [setMensagemResposta]);

    const handleEnter = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            formRef.current?.requestSubmit();
        }
    };

    const handleClickInputMensagem = () => {
        if (conversa && !isConversaInativa() && conversa.naoLida) {
            marcarConversaComoLida(conversa.codConversa);
        }
    };

    const limparFormulario = useCallback(() => {
        removerArquivo();
        cancelarRespostaMensagem();
        setTextoMensagem("");
    }, [removerArquivo, cancelarRespostaMensagem]);

    return (
        <>
            {arquivoNovaMensagem && (
                <div className="w-full py-2 px-4 flex justify-between items-center bg-white">
                    <span>{`${i18n.lbl_arquivo_selecionado}: ${arquivoNovaMensagem.length}`}</span>
                    <BotaoExcluir onClick={removerArquivo} />
                </div>
            )}

            {mensagemResposta && (
                <div className="w-full py-2 px-4 flex justify-between items-center bg-white border-l-green-700 border-l-8">
                    <CabecalhoMensagemResposta mensagemResposta={mensagemResposta} />
                    <BotaoExcluir onClick={cancelarRespostaMensagem} />
                </div>
            )}

            {audioEnvio && (
                <div className="w-full py-2 px-4 flex justify-between items-center bg-white">
                    <ReprodutorAudio
                        codMensagem={"audio-envio"}
                        src={URL.createObjectURL(audioEnvio)}
                        className="w-10/12"
                    />
                    <BotaoExcluir onClick={removerAudioEnvio} />
                </div>
            )}

            <form
                onSubmit={handleSubmit}
                ref={formRef}>
                <div className="p-4 bg-white border-t flex items-center w-full">
                    <div className="w-full flex gap-2 items-center justify-between">
                        <input
                            className="hidden"
                            ref={inputUpload}
                            onChange={handleUploadArquivo}
                            type="file"
                            multiple
                        />
                        {!isConversaInativa() && (
                            <button
                                className="rounded-full hover:cursor-pointer"
                                type="button"
                                onClick={handleClickUpload}>
                                <FontAwesomeIcon
                                    icon={faPaperclip}
                                    color="#226020"
                                    size="xl"
                                />
                            </button>
                        )}
                        <ListagemTemplates
                            aberto={exibeListagemTemplates}
                            setAberto={setExibeListagemTemplates}
                        />
                        <button
                            className="rounded-full hover:cursor-pointer"
                            type="button"
                            title={i18n.tlt_template_conversa_inativa}
                            onClick={clickListagemTemplates}>
                            <FontAwesomeIcon
                                icon={faBolt}
                                color="#226020"
                                size="xl"
                            />
                        </button>
                        <textarea
                            ref={textareaRef}
                            autoComplete="off"
                            placeholder={i18n.plc_escreva_mensagem}
                            onChange={(e) => setTextoMensagem(e.target.value)}
                            value={textoMensagem}
                            onClick={handleClickInputMensagem}
                            disabled={isConversaInativa()}
                            className={`text-black px-2 pt-4 bg-slate-50 w-full align-text-bottom rounded-xl overflow-y-hidden border resize-none focus:outline-none
                                ${isConversaInativa() && "bg-slate-200 hover:cursor-not-allowed"} max-h-[8rem] `}
                            onKeyDown={handleEnter}
                        />
                        {!isConversaInativa() && <BotaoGravacaoAudio />}
                    </div>
                </div>
            </form>
        </>
    );
};
export default FormMensagem;
