"use client;";
import { alterarNomeContatoConversa } from "@/components/barraLateral/listagem/FuncaoOpcaoConversa";
import { BotaoAzul } from "@/components/botoes/Botoes";
import { InputComLabel } from "@/components/inputs/Input";
import { ptBR } from "@/i18n/pt-br";
import { useModalStore } from "@/store/modalStore";
import { useState } from "react";
import { toast } from "react-toastify";

const i18n = ptBR;

interface AlterarNomeContatoProps {
    codConversa: string;
    nomeAtual: string;
}
const AlterarNomeContato = ({ codConversa, nomeAtual }: AlterarNomeContatoProps) => {
    const [novoNomeContato, setNovoNomeContato] = useState<string>("");
    const { fecharModal } = useModalStore();

    const handleAlterarNomeContato = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!novoNomeContato.trim()) {
            toast(i18n.err_todos_campos_obrigatorios, {
                type: "error",
                toastId: "erro-alterar-contato-campos-obrigatorios",
            });
        }
        try {
            await alterarNomeContatoConversa(novoNomeContato, codConversa);
        } catch {
            toast(i18n.err_alterar_nome_contato, {
                type: "error",
                toastId: "erro-alterar-nome",
            });
        } finally {
            fecharModal();
        }
    };

    return (
        <form
            className="flex w-full flex-col"
            onSubmit={handleAlterarNomeContato}>
            <h1 className="w-full text-center font-medium py-2 text-2xl">{i18n.tit_alterar_nome_contato}</h1>
            <span className="flex justify-start items-start mt-2">
                <InputComLabel
                    label={i18n.lbl_nome_atual}
                    id="nomeAtual"
                    desabilitado
                    valorPadrao={nomeAtual}
                />
            </span>

            <span className="flex justify-start items-start mt-2">
                <InputComLabel
                    label={i18n.lbl_novo_nome}
                    id="novoNome"
                    valor={novoNomeContato}
                    onChange={(e) => setNovoNomeContato(e.target.value)}
                />
            </span>
            <BotaoAzul
                label={i18n.btn_alterar_nome}
                className="mt-4 text-white"
                tipo="submit"
            />
        </form>
    );
};

export default AlterarNomeContato;
