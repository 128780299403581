import { faPaperPlane, faTrash, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const BotaoExcluir = ({ onClick }: { onClick: () => void }) => {
    return (
        <button
            type="button"
            onClick={onClick}
            className="py-2 px-3 rounded-full bg-red-500 hover:bg-red-700">
            <FontAwesomeIcon
                icon={faTrash}
                className="text-white"
            />
        </button>
    );
};

export const BotaoSubmitMensagem = () => {
    return (
        <button
            className="rounded-full pr-2 hover:cursor-pointer "
            type="submit">
            <FontAwesomeIcon
                icon={faPaperPlane}
                size="xl"
                color="#226020"
            />
        </button>
    );
};

export const BotaoAzul = ({
    className,
    onClick,
    label,
    tipo,
}: {
    className?: string;
    onClick?: () => void;
    label: string;
    tipo?: "button" | "reset" | "submit";
}) => {
    return (
        <button
            className={`rounded-full px-2 py-1 bg-blue-500 hover:cursor-pointer hover:bg-blue-300 ${className}`}
            type={tipo ?? "button"}
            onClick={onClick}>
            {label}
        </button>
    );
};

export const BotaoVerde = ({
    className,
    onClick,
    label,
    tipo,
}: {
    className?: string;
    onClick?: () => void;
    label: string;
    tipo?: "button" | "reset" | "submit";
}) => {
    return (
        <button
            className={`rounded-full px-2 py-1 bg-green-700 hover:cursor-pointer hover:bg-green-500 ${className}`}
            type={tipo ?? "button"}
            onClick={onClick}>
            {label}
        </button>
    );
};

interface MenuAgrupamentoProps {
    onClick: VoidFunction;
    titulo: string;
    icone: IconDefinition;
    valor?: number;
    className?: string;
}
export const MenuAgrupamento = ({ className, onClick, valor, titulo, icone }: MenuAgrupamentoProps) => {
    return (
        <div
            className={`flex hover:cursor-pointer py-4 w-full align-bottom ${className}`}
            onClick={onClick}>
            <button
                className={`mx-auto w-full`}
                title={titulo}>
                <FontAwesomeIcon
                    icon={icone}
                    size="lg"
                    inverse
                />
            </button>
            <span
                className={`mt-4 px-0.5 left-6 md:left-8 z-10 absolute text-sm 
                bg-red-700 rounded-full text-white ${!valor ? "hidden" : valor < 9 ? "!px-1.5" : ""}`}>
                {valor}
            </span>
        </div>
    );
};
