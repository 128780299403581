import { UsuarioType } from "@/types/UsuarioTypes";
import { create } from "zustand";

type UsuarioStore = {
    usuario: UsuarioType | null;
    alterarUsuario: (novoUsuario: UsuarioType | null) => void;
    removerUsuario: () => void;
};

export const useUsuarioStore = create<UsuarioStore>()((set) => ({
    usuario: null,
    alterarUsuario: (novoUsuario: UsuarioType | null) => {
        set((state) => ({
            usuario: novoUsuario,
        }));
    },
    removerUsuario: () => {
        set((state) => ({
            usuario: null,
        }));
    },
}));
