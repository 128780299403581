"use client";
import { useListagemLateralStore } from "@/store/listagemLateralStore";
import { useUsuarioStore } from "@/store/usuarioStore";
import { ConversaType } from "@/types/ConversaTypes";
import { StatusAtendimentoType } from "@/types/GeralTypes";
import { MenuLateralEnum } from "@/types/MenuTypes";
import { faChevronCircleLeft, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import ListagemConversa from "./listagem/ListagemConversas";
import MenuLateral from "./menu/MenuLateral";

const Sidebar = () => {
    const { adicionaOuAlterarConversa, removerConversa, menuLateral, atualizarTotalizadores } =
        useListagemLateralStore();
    const { usuario } = useUsuarioStore();
    const [menuAberto, setMenuAberto] = useState<Boolean>(false);
    const getStatusAtendimentoListagem = useCallback((): StatusAtendimentoType => {
        return menuLateral === MenuLateralEnum.SOLUCIONADO
            ? StatusAtendimentoType.SOLUCIONADO
            : StatusAtendimentoType.ABERTO;
    }, [menuLateral]);

    const atualizaListaConversas = (novaConversa: ConversaType) => {
        if (novaConversa.statusAtendimento === getStatusAtendimentoListagem()) {
            adicionaOuAlterarConversa(novaConversa);
        } else {
            removerConversa(novaConversa);
        }
    };

    useEffect(() => {
        const novoEventSource = new EventSource(`${process.env.NEXT_PUBLIC_CHAT_INSCREVER_SSE_CONVERSA_URL}`);
        const handleNovaConversa = (event: MessageEvent) => {
            const novaConversa: ConversaType = JSON.parse(event.data);
            if (
                menuLateral === MenuLateralEnum.MINHAS_CONVERSAS &&
                (!novaConversa.usuarioResponsavel?.login || novaConversa.usuarioResponsavel.login !== usuario?.login)
            ) {
                removerConversa(novaConversa);
                atualizarTotalizadores();
                return;
            }

            if (
                menuLateral === MenuLateralEnum.MEUS_SETORES &&
                (!novaConversa.setor ||
                    !usuario?.setores
                        .replace(/\s+/g, "")
                        .split(",")
                        .some((setor) => setor.toLowerCase() === novaConversa.setor?.toLowerCase()))
            ) {
                removerConversa(novaConversa);
                atualizarTotalizadores();
                return;
            }
            atualizaListaConversas(novaConversa);
            atualizarTotalizadores();
        };

        novoEventSource.addEventListener("message", handleNovaConversa);
        novoEventSource.onerror = (error) => {
            console.error("Ocorreu um erro ao se contectar ao SSE:", error);
        };
        return () => {
            novoEventSource.removeEventListener("message", handleNovaConversa);
            novoEventSource.close();
        };
    }, [menuLateral, usuario?.login]);

    const clickAbrirMenuLateral = () => {
        setMenuAberto(!menuAberto);
    };

    return (
        <>
            <div className={`${menuAberto ? "w-0" : "w-2 md:hidden"} flex flex-col h-full bg-green-900`}>
                <button
                    className={`${menuAberto ? "ml-80" : ""} my-auto z-10 border-slate-400`}
                    onClick={clickAbrirMenuLateral}>
                    <FontAwesomeIcon
                        icon={menuAberto ? faChevronCircleLeft : faChevronCircleRight}
                        size="xl"
                        color="green"
                    />
                </button>
            </div>
            <div className={`${menuAberto ? "block" : "hidden"}  h-full w-80 md:block md:w-96`}>
                <div className="h-full flex">
                    <MenuLateral />
                    <section className="h-full w-96 min-w-[150px]">
                        <ListagemConversa />
                    </section>
                </div>
            </div>
        </>
    );
};

export default Sidebar;
