import { visualizarMensagensConversa } from "@/api/chatAPI";
import { ptBR } from "@/i18n/pt-br";
import { ConversaType } from "@/types/ConversaTypes";
import { MensagemType } from "@/types/MensagemTypes";
import { ordenarMensagensPorData } from "@/util/ChatUtils";
import { isDataMaiorQue24Horas } from "@/util/DateUtils";
import { CookieEnum } from "@/util/EnumUtils";
import { CompatClient, IMessage, Stomp, StompSubscription } from "@stomp/stompjs";
import { getCookie } from "cookies-next";
import { toast } from "react-toastify";
import SockJS from "sockjs-client";
import { create } from "zustand";

const getChatWebSocketURL = () => process.env.NEXT_PUBLIC_CHAT_API_WEBSOCKET_URL;
const i18n = ptBR;

interface ConversaState {
    conversa: ConversaType | null;
    stompClient: CompatClient | null;
    conversaSubscriptionWS: StompSubscription | null;
    carregando: boolean;
    mensagens: MensagemType[];
    mensagemEnviadaPeloUsuario: boolean;
    paginaMensagens: number;
    tamanhoPagina: number;
    totalPaginas: number;
    dtHoraUltimaMensagemContato: Date | null;
}

interface ConversaActions {
    alteraCarregando: (carregando: boolean) => void;
    adicionarMensagem: (novaMensagem: MensagemType) => void;
    alterarConversaAtiva: (novaConversa: ConversaType) => void;
    adicionarListaMensagens: (listaMensagens: MensagemType[]) => void;
    desconectarWS: () => void;
    conectarWS: () => void;
    desconectarConversaAntiga: (codConversaAntiga: string | null | undefined) => void;
    isConversaInativa: () => boolean;
    alterarPaginaMensagens: (novaPagina: number) => void;
    alterarTotalPaginas: (novoTotalPaginas: number) => void;
    alterarDtHoraUltimaMensagemContato: (dtHoraUltimaMensagemContato: Date | null) => void;
    marcarConversaComoLida: (codConversa: string) => void;
}

export const useConversaStore = create<ConversaState & ConversaActions>((set, get) => ({
    conversa: null,
    mensagens: [],
    stompClient: null,
    carregando: false,
    mensagemEnviadaPeloUsuario: false,
    paginaMensagens: 0,
    tamanhoPagina: 50,
    totalPaginas: 1,
    dtHoraUltimaMensagemContato: null,
    conversaSubscriptionWS: null,

    alteraCarregando: (carregando: boolean) => set({ carregando }),

    adicionarListaMensagens: (listaMensagens: MensagemType[]) => {
        set((state) => ({
            mensagens: ordenarMensagensPorData([...state.mensagens, ...listaMensagens]),
        }));
    },

    adicionarMensagem: (novaMensagem: MensagemType) => {
        const loginUsuario =
            process.env.NEXT_PUBLIC_AMBIENTE === "local" ? "fvieira" : getCookie(CookieEnum.LOGIN_USUARIO);

        const mensagensAtuais = get().mensagens;
        const index = mensagensAtuais.findIndex((mensagem) => mensagem.codMensagem === novaMensagem.codMensagem);

        const listaMensagens =
            index > -1
                ? [...mensagensAtuais.slice(0, index), novaMensagem, ...mensagensAtuais.slice(index + 1)]
                : [...mensagensAtuais, novaMensagem];

        const isMensagemEnviadaPeloUsuario = loginUsuario
            ? novaMensagem.remetente.toLocaleLowerCase() === loginUsuario.toLocaleLowerCase()
            : false;

        set({ mensagens: listaMensagens, mensagemEnviadaPeloUsuario: isMensagemEnviadaPeloUsuario });
    },

    alterarConversaAtiva: (novaConversa: ConversaType) => set({ conversa: novaConversa }),

    conectarWS: () => {
        const conversa = get().conversa;
        if (!conversa) {
            return;
        }

        if (get().stompClient && get().stompClient?.connected) {
            return;
        }

        const url = getChatWebSocketURL();
        if (!url) {
            toast(i18n.err_url_websocket_invalida, { toastId: "erro-conectar-ws", type: "error" });
            return;
        }
        const socketFactory = () => new SockJS(url);
        const stompClient = Stomp.over(socketFactory);

        stompClient.connect(
            {},
            () => {
                const subscription = stompClient.subscribe(
                    `/conversa/${conversa.codConversa}/nova-mensagem`,
                    (mensagemWS: IMessage) => {
                        try {
                            const novaMensagem: MensagemType = JSON.parse(mensagemWS.body);
                            if (novaMensagem.codConversa !== conversa?.codConversa) return;
                            get().adicionarMensagem(novaMensagem);
                        } catch (error) {
                            console.error("Erro ao processar mensagem do websocket", error);
                        }
                    }
                );
                set({ conversaSubscriptionWS: subscription });
                visualizarMensagensConversa(conversa.codConversa);
            },
            (error: any) => {
                toast(i18n.err_conectar_ws, { toastId: "erro-conectar-ws", type: "error" });
                console.error(i18n.err_conectar_ws, error);
            }
        );

        set({ stompClient });
    },

    desconectarWS: () => {
        const { stompClient, conversaSubscriptionWS } = get();
        if (conversaSubscriptionWS) {
            conversaSubscriptionWS.unsubscribe();
            set({ conversaSubscriptionWS: null });
        }
        if (stompClient && stompClient.connected) {
            stompClient.disconnect(() => {
                console.info("WebSocket desconectado com sucesso.");
            });
        }
        set({ stompClient: null, dtHoraUltimaMensagemContato: null, mensagens: [] });
    },

    desconectarConversaAntiga: (codConversaAntiga: string | null | undefined) => {
        const { conversaSubscriptionWS, stompClient } = get();
        if (codConversaAntiga && conversaSubscriptionWS && stompClient?.connected) {
            conversaSubscriptionWS.unsubscribe();
            stompClient.disconnect(() => {
                console.info("WebSocket desconectado com sucesso.");
            });
            set({ conversaSubscriptionWS: null, stompClient: null });
        }
        set({ mensagens: [], dtHoraUltimaMensagemContato: null });
    },

    isConversaInativa: () => {
        const dtHoraUltimaMensagemContato = get().dtHoraUltimaMensagemContato;
        return isDataMaiorQue24Horas(dtHoraUltimaMensagemContato);
    },

    alterarPaginaMensagens: (novaPagina: number) => set({ paginaMensagens: novaPagina }),
    alterarTotalPaginas: (novaTotalPaginas: number) => set({ totalPaginas: novaTotalPaginas }),
    alterarDtHoraUltimaMensagemContato: (dtHoraUltimaMensagemContato: Date | null) =>
        set({ dtHoraUltimaMensagemContato }),

    marcarConversaComoLida: (codConversa: string) => {
        visualizarMensagensConversa(codConversa);
    },
}));
