export const formatarTelefone = (telefone: string | undefined): string => {
    if (!telefone) {
        return "";
    }
    let telefoneApenasNumerico = telefone.replace(/\D/g, "");
    const telefoneNaoPossuiCodigoArea = telefoneApenasNumerico.length === 10 || telefoneApenasNumerico.length === 11;

    telefoneApenasNumerico = telefoneNaoPossuiCodigoArea ? `55${telefoneApenasNumerico}` : telefoneApenasNumerico;

    if (telefoneApenasNumerico.length === 13) {
        const ddd = telefoneApenasNumerico.slice(2, 4);
        const numero = telefoneApenasNumerico.slice(4);
        return `(${ddd}) ${numero.slice(0, 5)}-${numero.slice(5)}`;
    } else if (telefoneApenasNumerico.length === 12) {
        const ddd = telefoneApenasNumerico.slice(2, 4);
        const numero = telefoneApenasNumerico.slice(4);
        return `(${ddd}) ${numero.slice(0, 4)}-${numero.slice(4)}`;
    } else {
        return telefone;
    }
};

export const formatarTextoComLinks = (texto: string): string => {
    // Busca Links com descrição
    const regexDescricaoComUrl = /\[([^\]]+)\]\s*\.?\s*(https?:\/\/[^\s]+)/g;
    let textoTransformado = texto.replace(
        regexDescricaoComUrl,
        (_, descricao: string, url: string) =>
            `<a href='${url}' target='_blank' class='text-blue-600 hover:underline'>${descricao}</a>`
    );

    // Busca Links sem descrição
    if (texto === textoTransformado) {
        const regexUrl = /\b(https?:\/\/[^\s]+)\b/g;
        textoTransformado = textoTransformado.replace(
            regexUrl,
            (url: string) => `<a href='${url}' target='_blank' class='text-blue-600 hover:underline'>${url}</a>`
        );
    }

    return textoTransformado;
};
