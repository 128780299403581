"use client";
import { CheckDuploMensagemSVG, CheckUnicoMensagem, TrianguloCaixaMensagemSVG } from "@/components/icones/MensagemSVG";
import { useConversaStore } from "@/store/conversaStore";
import { MensagemType } from "@/types/MensagemTypes";
import { formatarStringParaDate } from "@/util/DateUtils";
import { TipoMensagemEnum } from "@/util/EnumUtils";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ConteudoMensagem from "./ConteudoMensagem";
import OpcoesMensagem from "./OpcoesMensagem";

interface CaixaMensagemProps {
    nomeContato: string;
    mensagem: MensagemType;
    setMensagemResposta: React.Dispatch<React.SetStateAction<MensagemType | undefined>>;
}

const CaixaMensagem = ({ nomeContato, mensagem, setMensagemResposta }: CaixaMensagemProps) => {
    const [mostrarOpcoes, setMostrarOpcoes] = useState(false);
    const { dtHoraUltimaMensagemContato, alterarDtHoraUltimaMensagemContato } = useConversaStore();

    const clickOpcoes = () => {
        setMostrarOpcoes(!mostrarOpcoes);
    };

    const isHistorico = mensagem?.remetente?.toLocaleLowerCase() === TipoMensagemEnum.HISTORICO;
    const isUsuarioRemetente =
        mensagem?.remetente?.toLocaleLowerCase() !== nomeContato?.toLocaleLowerCase() && !mensagem.isMensagemContato;

    if (
        !isUsuarioRemetente &&
        (!dtHoraUltimaMensagemContato || formatarStringParaDate(mensagem.dataHoraEnvio) > dtHoraUltimaMensagemContato)
    ) {
        alterarDtHoraUltimaMensagemContato(formatarStringParaDate(mensagem.dataHoraEnvio));
    }

    const renderCheckStatusMensagem = () => {
        if (isHistorico) return;
        if (mensagem.statusEnvio === "ENVIADA") {
            return <CheckUnicoMensagem />;
        } else {
            return <CheckDuploMensagemSVG fill={mensagem.statusLeitura === "LIDA" ? "#53bdeb" : "gray"} />;
        }
    };

    const renderEstiloFundoCaixaMensagem = () => {
        if (isHistorico) {
            return "bg-yellow-100 text-black rounded-lg shadow-md";
        }

        if (isUsuarioRemetente) {
            return "bg-green-100 text-black rounded-l-lg shadow-md";
        } else {
            return "bg-gray-100 rounded-r-lg shadow-lg";
        }
    };

    return (
        <div
            id={mensagem.codMensagem}
            className={`flex mx-5 mb-1 ${isUsuarioRemetente && "justify-end items-start"}`}>
            <div className={`flex flex-col mb-2 max-w-5xl md:max-w-[95%] ${isUsuarioRemetente && "items-end"} group`}>
                <div className="flex">
                    {!isUsuarioRemetente && (
                        <div className="flex items-start">
                            <TrianguloCaixaMensagemSVG
                                className="transform scale-x-[-1]"
                                fill="#F7F8FA"
                            />
                        </div>
                    )}

                    {isUsuarioRemetente && !isHistorico && (
                        <>
                            <OpcoesMensagem
                                mostrarOpcoes={mostrarOpcoes}
                                isRemetente={isUsuarioRemetente}
                                mensagem={mensagem}
                                setMensagemResposta={setMensagemResposta}
                                clickOpcao={clickOpcoes}
                            />

                            <button
                                className={`invisible group-hover:visible px-1.5 my-auto h-6 bg-green-500 rounded-full mr-2`}
                                onClick={() => setMostrarOpcoes(!mostrarOpcoes)}>
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    size="sm"
                                />
                            </button>
                        </>
                    )}
                    <div
                        className={`flex items-center justify-center text-sm w-fit overflow-hidden ${renderEstiloFundoCaixaMensagem()} shadow-gray-300 shadow-bottom`}>
                        <div className="flex flex-col relative mx-1 rounded-b-lg py-1">
                            {isUsuarioRemetente && (
                                <div className="flex w-full justify-end ">
                                    <span
                                        className={`text-center px-2 w-fit mr-2 ${isHistorico ? "bg-yellow-400 text-black" : "bg-green-700 text-white"} rounded-md font-semibold`}>
                                        {mensagem.remetente}
                                    </span>
                                </div>
                            )}

                            {ConteudoMensagem(mensagem, isUsuarioRemetente)}

                            <span className={`flex justify-end items-center gap-1 px-2 text-xs text-gray-500 `}>
                                {mensagem.horaEnvio}
                                {isUsuarioRemetente && renderCheckStatusMensagem()}
                            </span>
                        </div>
                    </div>

                    {!isUsuarioRemetente && (
                        <>
                            <button
                                className={`invisible group-hover:visible px-1.5 my-auto h-6 bg-green-500 rounded-full ml-2`}
                                onClick={() => setMostrarOpcoes(!mostrarOpcoes)}>
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    size="sm"
                                />
                            </button>
                            <OpcoesMensagem
                                mostrarOpcoes={mostrarOpcoes}
                                isRemetente={isUsuarioRemetente}
                                mensagem={mensagem}
                                setMensagemResposta={setMensagemResposta}
                                clickOpcao={clickOpcoes}
                            />
                        </>
                    )}

                    {isUsuarioRemetente && !isHistorico && (
                        <div className="flex items-start">
                            <TrianguloCaixaMensagemSVG fill="#d1f4cc" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CaixaMensagem;
