import { CheckDuploMensagemSVG, TrianguloCaixaMensagemSVG } from "../icones/MensagemSVG";

export const EsqueletoMensagens = () => {
    return (
        <>
            <div className="flex mx-5 mb-[2px] justify-end items-start transition animate-pulse flex-row ">
                <div className="flex flex-col mb-2 w-1/3 max-w-[50%] items-end">
                    <div className="flex w-full">
                        <div
                            className={`flex w-full overflow-hidden bg-green-100 
                                        py-2 px-3  rounded-l-lg rounded-b-lg shadow-md shadow-gray-300 shadow-bottom`}>
                            <div className="flex flex-col relative w-full">
                                <span className={`h-4 bg-green-300 rounded-md font-semibold`}></span>

                                <p className="h-6 mt-1 w-full bg-gray-400"></p>
                                <p className="h-6 mt-1 w-full bg-gray-400"></p>
                                <p className="h-6 mt-1 w-1/2 bg-gray-400"></p>

                                <span className={`h-5 flex justify-end items-center gap-1 mt-4  `}>
                                    <CheckDuploMensagemSVG fill={"#53bdeb"} />
                                </span>
                            </div>
                        </div>
                        <div className="flex items-start">
                            <TrianguloCaixaMensagemSVG fill="#d1f4cc" />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`flex mx-5 mb-[2px] transition animate-pulse flex-row `}>
                <div className={`flex flex-col mb-2 w-1/3  max-w-[50%]`}>
                    <div className="flex w-full">
                        <div className="flex items-start">
                            <TrianguloCaixaMensagemSVG
                                className="transform scale-x-[-1] mr-[-0.5px]"
                                fill="#F7F8FA"
                            />
                        </div>
                        <div
                            className={`flex w-full overflow-hidden bg-gray-100 py-2 px-3 rounded-r-lg shadow-lg
                                        rounded-b-lg shadow-gray-300 shadow-bottom`}>
                            <div className="flex flex-col relative w-full max-w-[600px]">
                                <p className="h-6 mt-1 w-full bg-gray-400"></p>
                                <p className="h-6 mt-1 w-full bg-gray-400"></p>
                                <p className="h-6 mt-1 w-1/2 bg-gray-400"></p>

                                <span className={`h-5 mt-4 flex justify-end  `}>
                                    <span className="h-5 w-1/4 bg-gray-400"></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
