"use client";
import { iniciarConversaInativa } from "@/api/chatAPI";
import { ptBR } from "@/i18n/pt-br";
import { useConversaStore } from "@/store/conversaStore";
import { useUsuarioStore } from "@/store/usuarioStore";
import { TemplateEnum } from "../../util/EnumUtils";
import { TrianguloCaixaMensagemSVG } from "../icones/MensagemSVG";

const i18n = ptBR;

interface ListagemTemplatesProps {
    aberto: boolean;
    setAberto: (aberto: boolean) => void;
}

const ListagemTemplates = ({ aberto, setAberto }: ListagemTemplatesProps) => {
    const { conversa, stompClient, marcarConversaComoLida } = useConversaStore();
    const { usuario } = useUsuarioStore();

    const handleEnvioDeTemplate = (template: TemplateEnum) => {
        if (!conversa || !stompClient || !usuario) return;

        switch (template) {
            case TemplateEnum.INICIAR_NOVA_CONVERSA:
                iniciarConversaInativa(
                    conversa.telContato,
                    [conversa.nomeContato.split(" ")[0], usuario.nomeCompleto.split(" ")[0] ?? i18n.npr_usuario_padrao],
                    template
                );
                break;
            case TemplateEnum.SOLICITAR_DOCUMENTOS_CADASTRO:
                const anoAtual = new Date().getFullYear();
                iniciarConversaInativa(conversa.telContato, [`${anoAtual - 1}/${anoAtual}`], template);
                break;
            case TemplateEnum.SOLICITAR_FOTO_NOTA_FISCAL:
            case TemplateEnum.SOLICITAR_FOTO_TICKET_LAUDO_NF:
            case TemplateEnum.SOLICITAR_TICKET_DESCARGA_PAGAMENTO_SALDO:
            case TemplateEnum.SOLICITAR_PREVISAO_DESCARGA:
                iniciarConversaInativa(
                    conversa.telContato,
                    [conversa.nomeContato?.split(" ")[0] ?? i18n.npr_motorista],
                    template
                );
                break;
            default:
                break;
        }
        marcarConversaComoLida(conversa.codConversa);
        setAberto(false);
    };

    return (
        <div className={` ${aberto ? "fixed" : "hidden"} max-w-72 z-20 mb-96 ml-3`}>
            <nav className="bg-white p-4 flex flex-col shadow-lg rounded-r-md rounded-tl-md">
                <ul
                    className="[&>li]:hover:cursor-pointer [&>li]:py-2 [&>li]:border-b
                 text-left">
                    <li
                        className="hover:font-semibold"
                        onClick={() => handleEnvioDeTemplate(TemplateEnum.INICIAR_NOVA_CONVERSA)}>
                        {i18n.tpt_template_iniciar_conversa}
                    </li>
                    <li
                        className="hover:font-semibold"
                        onClick={() => handleEnvioDeTemplate(TemplateEnum.SOLICITAR_DOCUMENTOS_CADASTRO)}>
                        {i18n.tpt_template_solicitar_documentos_cadastro}
                    </li>
                    <li
                        className="hover:font-semibold"
                        onClick={() => handleEnvioDeTemplate(TemplateEnum.SOLICITAR_TICKET_DESCARGA_PAGAMENTO_SALDO)}>
                        {i18n.tpt_template_solicitar_ticket_descarga}
                    </li>
                    <li
                        className="hover:font-semibold"
                        onClick={() => handleEnvioDeTemplate(TemplateEnum.SOLICITAR_PREVISAO_DESCARGA)}>
                        {i18n.tpt_template_solicitar_previsao_descarga}
                    </li>
                    <li
                        className="hover:font-semibold"
                        onClick={() => handleEnvioDeTemplate(TemplateEnum.SOLICITAR_FOTO_NOTA_FISCAL)}>
                        {i18n.tpt_template_solicitar_foto_nota_fiscal}
                    </li>
                    <li
                        className="hover:font-semibold border-none"
                        onClick={() => handleEnvioDeTemplate(TemplateEnum.SOLICITAR_FOTO_TICKET_LAUDO_NF)}>
                        {i18n.tpt_template_solicitar_foto_ticket_laudo_nf}
                    </li>
                </ul>
            </nav>

            <TrianguloCaixaMensagemSVG
                className="fixed transform -mt-1"
                fill="#fff"
            />
        </div>
    );
};

export default ListagemTemplates;
