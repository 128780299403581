"use client";
import { retornaInfoUsuarioLogado, retornarMenuTotalizadores } from "@/api/chatAPI";
import Sidebar from "@/components/barraLateral/Sidebar";
import ChatMain from "@/components/conversa/Container";
import Main from "@/components/main/Main";
import { ptBR } from "@/i18n/pt-br";
import { useConversaStore } from "@/store/conversaStore";
import { useListagemLateralStore } from "@/store/listagemLateralStore";
import { useUsuarioStore } from "@/store/usuarioStore";
import { isNullOrEmpty } from "@/util/ChatUtils";
import { CookieEnum } from "@/util/EnumUtils";
import { getCookie, setCookie } from "cookies-next";
import { useRouter, useSearchParams } from "next/navigation";
import { Suspense, useEffect } from "react";
import { toast } from "react-toastify";

const i18n = ptBR;

function ConteudoHome() {
    const router = useRouter();
    const searchParams = useSearchParams();
    const { alterarConversaAtiva, alteraCarregando } = useConversaStore();
    const { alterarUsuario, removerUsuario } = useUsuarioStore();
    const { iniciarTotalizadores } = useListagemLateralStore();

    useEffect(() => {
        const telefone = searchParams.get("telefone");
        let loginUsuario = getCookie(CookieEnum.LOGIN_USUARIO);

        if (process.env.NEXT_PUBLIC_AMBIENTE === "local") {
            loginUsuario = "fvieira";
        }

        if (!isNullOrEmpty(telefone)) {
            carregarConversaPorTelefone(telefone).finally(() => {
                alteraCarregando(false);
            });
        }

        if (!loginUsuario) {
            removerUsuario();
            router.push("/login");
        }

        retornaInfoUsuarioLogado(loginUsuario as string)
            .then((usuario) => {
                retornarMenuTotalizadores(usuario.login, usuario.setores.split(",")).then((menuTotalizadores) => {
                    const { totalAbertasNaoLidas, totalSetorNaoLidas, totalUsuarioNaoLidas } = menuTotalizadores;
                    iniciarTotalizadores(totalAbertasNaoLidas, totalSetorNaoLidas, totalUsuarioNaoLidas);
                });
                alterarUsuario(usuario);
                setCookie(CookieEnum.SETORES_USUARIO, usuario.setores, { maxAge: 60 * 60 * 24 * 30 });
            })
            .catch((erro) => {
                toast(erro.message, {
                    toastId: "erro-info-usuario",
                    type: "error",
                });
            });
    }, []);

    const carregarConversaPorTelefone = async (telefone: string | null) => {
        try {
            if (!telefone) return;
            const url = `${process.env.NEXT_PUBLIC_CHAT_API_REST_GET_RETORNAR_CONVERSA_POR_TELEFONE_URL}?telefone=${telefone}`;
            const retorno = await fetch(url, { cache: "no-store" });
            if (!retorno.ok) throw new Error(i18n.err_buscar_conversa);
            const conversa = await retorno.json();
            alterarConversaAtiva(conversa);
        } catch (erro: any) {
            if (erro.name !== "AbortError") {
                toast(erro.message, { toastId: "erro-retornar-conversa", type: "error" });
            }
        }
    };

    return (
        <>
            <Main>
                <Sidebar />
                <ChatMain />
            </Main>
        </>
    );
}

export default function Home() {
    return (
        <Suspense
            fallback={
                <>
                    <Main>
                        <Sidebar />
                        <ChatMain />
                    </Main>
                </>
            }>
            <ConteudoHome />
        </Suspense>
    );
}
