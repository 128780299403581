"use client";
import { ptBR } from "@/i18n/pt-br";
import Logo from "@/public/images/logo_verde.png";
import { useConversaStore } from "@/store/conversaStore";
import { MensagemType } from "@/types/MensagemTypes";
import Image from "next/image";
import { useEffect, useState } from "react";
import Cabecalho from "./Cabecalho";
import CorpoConversa from "./CorpoConversa";
import FormMensagem from "./FormMensagem";

const i18n = ptBR;
const Container = () => {
    const [mensagemResposta, setMensagemResposta] = useState<MensagemType>();
    const { conversa, conectarWS, desconectarWS } = useConversaStore();

    useEffect(() => {
        conectarWS();
        return () => {
            desconectarWS();
        };
    }, [conversa]);

    return (
        <div className="w-full bg-chat-white flex flex-col">
            {conversa ? (
                <>
                    <Cabecalho />
                    <CorpoConversa setMensagemResposta={setMensagemResposta} />
                    <FormMensagem
                        mensagemResposta={mensagemResposta}
                        setMensagemResposta={setMensagemResposta}
                    />
                </>
            ) : (
                <Image
                    src={Logo}
                    alt={i18n.alt_logo_rodosafra}
                    className="mx-auto my-10"
                    unoptimized
                />
            )}
        </div>
    );
};

export default Container;
