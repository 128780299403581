export type MenuItemType = {
    titulo: string;
    rota?: string;
    itemFilho?: MenuItemType[];
    regra?: string;
    subMenuCodGrupo?: number;
};

export enum MenuLateralEnum {
    CONVERSAS_ABERTAS = "Conversas Abertas",
    MEUS_SETORES = "Meus Setores",
    MINHAS_CONVERSAS = "Minhas Conversas",
    SOLUCIONADO = "Conversas Solucionadas",
    MINHAS_ESTATISTICAS = "Minhas Estatísticas",
}

export type MenuLateralType = MenuLateralEnum;
