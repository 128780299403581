export enum SetorEnum {
    Cadastro = "Cadastro",
    Agendamento = "Agendamento",
    Fiscal = "Fiscal",
    Descarga = "Descarga",
    Financeiro = "Financeiro",
    Logistica = "Logística",
}

export enum TemplateEnum {
    INICIAR_CONVERSA_MOT_FRETE_ATIVO = "INICIAR_CONVERSA_MOT_FRETE_ATIVO",
    INICIAR_NOVA_CONVERSA = "INICIAR_NOVA_CONVERSA",
    SOLICITAR_LOCALIZACAO = "SOLICITAR_LOCALIZACAO",
    SOLICITAR_DOCUMENTOS_CADASTRO = "SOLICITAR_DOCUMENTOS_CADASTRO",
    ANALISE_GERENCIAMENTO_RISCO = "ANALISE_GERENCIAMENTO_RISCO",
    SOLICITAR_FOTO_TICKET_LAUDO_NF = "SOLICITAR_FOTO_TICKET_LAUDO_NF",
    SOLICITAR_FOTO_NOTA_FISCAL = "SOLICITAR_FOTO_NOTA_FISCAL",
    SOLICITAR_PREVISAO_DESCARGA = "SOLICITAR_PREVISAO_DESCARGA",
    SOLICITAR_TICKET_DESCARGA_PAGAMENTO_SALDO = "SOLICITAR_TICKET_DESCARGA_PAGAMENTO_SALDO",
}

export enum AmbienteEnum {
    PRODUCAO = "prod",
    HOMOLOGACAO = "hom",
    LOCAL = "local",
}

export enum TipoMensagemEnum {
    TEXT = "text",
    AUDIO = "audio",
    INTERACTIVE = "interactive",
    DOCUMENT = "document",
    IMAGE = "image",
    TEMPLATE = "template",
    STICKER = "sticker",
    REACTION = "reaction",
    UNKNOWNS = "unknowns",
    SYSTEM = "system",
    VIDEO = "video",
    LOCATION = "location",
    CONTACTS = "contacts",
    BUTTONS = "buttons",
    HISTORICO = "historico",
}

export enum CookieEnum {
    LOGIN_USUARIO = "rsUsuLogin",
    SETORES_USUARIO = "rsUsuSetores",
    FUNC_USUARIO = "rsUsuFunc",
    JWT = "__Secure-Fgp",
}
