"use client";
import { BotaoTransferir } from "@/components/botoes/BotaoTransferir";
import AlterarNomeContato from "@/components/modal/conteudo/AlterarNomeContato";
import { ptBR } from "@/i18n/pt-br";
import { useModalStore } from "@/store/modalStore";
import { useUsuarioStore } from "@/store/usuarioStore";
import { ConversaType } from "@/types/ConversaTypes";
import { StatusAtendimentoType } from "@/types/GeralTypes";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { alterarResponsavelConversa, arquivarConversa, marcarComoNaoLida } from "./FuncaoOpcaoConversa";

const i18n = ptBR;
interface OpcoesConversaProps {
    conversa: ConversaType;
    clickOpcao: () => void;
    mostrarOpcoes: boolean;
}
const OpcoesConversa = ({ conversa, clickOpcao, mostrarOpcoes }: OpcoesConversaProps) => {
    const [opcTransferirVisivel, setOpcTransferirVisivel] = useState(false);
    const { usuario } = useUsuarioStore();
    const { abrirModal } = useModalStore();

    const handleMouseEnterTransferir = () => {
        setOpcTransferirVisivel(true);
    };
    const handleMouseLeaveTransferir = () => {
        setOpcTransferirVisivel(false);
    };

    const renderBotaoVincular = () => {
        if (usuario && (!conversa.usuarioResponsavel?.login || conversa.usuarioResponsavel.login !== usuario.login)) {
            return (
                <button
                    className="w-full p-2 hover:cursor-pointer hover:bg-green-950"
                    onClick={() => {
                        alterarResponsavelConversa(usuario.login, conversa.codConversa, false);
                        clickOpcao();
                    }}>
                    {i18n.opc_assumir}
                </button>
            );
        }
    };

    const renderBotaoDesvincular = () => {
        if (usuario && conversa.usuarioResponsavel?.login && conversa.usuarioResponsavel.login === usuario.login) {
            return (
                <button
                    className="w-full p-2 hover:cursor-pointer hover:bg-green-950"
                    onClick={() => {
                        alterarResponsavelConversa(null, conversa.codConversa, true);
                        clickOpcao();
                    }}>
                    {i18n.opc_desvincular}
                </button>
            );
        }
    };

    return (
        <>
            <nav
                className={`${mostrarOpcoes ? "absolute" : "hidden"} left-40 md:left-48 2xl:left-[14.3rem] top-6 w-1/3 z-30 text-slate-100 shadow-lg 
            bg-green-700 rounded-l-md rounded-b-md text-sm`}>
                <ul className="w-full flex flex-col items-end justify-start">
                    {renderBotaoVincular()}
                    {renderBotaoDesvincular()}
                    {!conversa.naoLida && (
                        <button
                            className="w-full p-2 hover:cursor-pointer hover:bg-green-950"
                            onClick={() => {
                                marcarComoNaoLida(conversa);
                                clickOpcao();
                            }}>
                            {i18n.opc_nao_lida}
                        </button>
                    )}
                    <button
                        className="w-full p-2 hover:cursor-pointer hover:bg-green-950"
                        onClick={() => {
                            arquivarConversa(conversa);
                            clickOpcao();
                        }}>
                        {conversa.statusAtendimento === StatusAtendimentoType.ABERTO
                            ? i18n.opc_solucionado
                            : i18n.opc_reabrir}
                    </button>

                    <button
                        className="w-full p-2 hover:cursor-pointer hover:bg-green-950"
                        onClick={() => {
                            clickOpcao();
                            abrirModal(
                                <AlterarNomeContato
                                    codConversa={conversa.codConversa}
                                    nomeAtual={conversa.nomeContato}
                                />
                            );
                        }}>
                        {i18n.btn_alterar_nome}
                    </button>

                    <button
                        className="w-full static p-2 hover:cursor-pointer hover:bg-green-950"
                        onMouseEnter={handleMouseEnterTransferir}
                        onMouseLeave={handleMouseLeaveTransferir}>
                        <span className="flex justify-between items-center">
                            <FontAwesomeIcon
                                icon={faAngleLeft}
                                inverse
                            />
                            {i18n.opc_transferir}
                            <span />
                        </span>

                        {opcTransferirVisivel && (
                            <BotaoTransferir
                                conversa={conversa}
                                usuarioAlteracao={usuario?.nomeCompleto}
                            />
                        )}
                    </button>
                </ul>
            </nav>
            {mostrarOpcoes ? (
                <button
                    className="fixed top-0 right-0 bottom-0 left-0 z-20"
                    onClick={clickOpcao}></button>
            ) : (
                <></>
            )}
        </>
    );
};
export default OpcoesConversa;
