"use client";
import { alterarUsuarioResponsavel } from "@/api/chatAPI";
import { MenuAgrupamento } from "@/components/botoes/Botoes";
import { ptBR } from "@/i18n/pt-br";
import { useListagemLateralStore } from "@/store/listagemLateralStore";
import { useUsuarioStore } from "@/store/usuarioStore";
import { MenuLateralEnum } from "@/types/MenuTypes";
import { faBoxArchive, faChainSlash, faComments, faUser, faUsersRectangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const i18n = ptBR;
export default function MenuLateral() {
    const { menuLateral, alterarMenuLateral, totalAbertasNaoLidas, totalMinhasNaoLidas, totalSetoresNaoLidas } =
        useListagemLateralStore();
    const { usuario } = useUsuarioStore();

    const clickDesassociarTodasConversas = (): void => {
        if (usuario) {
            alterarUsuarioResponsavel(usuario.login, null, true, usuario.nomeCompleto);
        }
    };

    return (
        <>
            <nav className={`w-16 h-full bg-green-900`}>
                <ul className="h-full flex flex-col justify-between pb-4">
                    <div className="flex flex-col">
                        <MenuAgrupamento
                            className={`${menuLateral === MenuLateralEnum.CONVERSAS_ABERTAS ? "bg-green-700" : ""}`}
                            onClick={() => alterarMenuLateral(MenuLateralEnum.CONVERSAS_ABERTAS)}
                            icone={faComments}
                            titulo={MenuLateralEnum.CONVERSAS_ABERTAS}
                            valor={totalAbertasNaoLidas}
                        />

                        <MenuAgrupamento
                            className={`${menuLateral === MenuLateralEnum.MEUS_SETORES ? "bg-green-700" : ""}`}
                            onClick={() => alterarMenuLateral(MenuLateralEnum.MEUS_SETORES)}
                            icone={faUsersRectangle}
                            titulo={MenuLateralEnum.MEUS_SETORES}
                            valor={totalSetoresNaoLidas}
                        />

                        <MenuAgrupamento
                            className={`${menuLateral === MenuLateralEnum.MINHAS_CONVERSAS ? "bg-green-700" : ""}`}
                            onClick={() => alterarMenuLateral(MenuLateralEnum.MINHAS_CONVERSAS)}
                            icone={faUser}
                            titulo={MenuLateralEnum.MINHAS_CONVERSAS}
                            valor={totalMinhasNaoLidas}
                        />
                        <hr className="opacity-50" />

                        <MenuAgrupamento
                            className={`${menuLateral === MenuLateralEnum.SOLUCIONADO ? "bg-green-700" : ""}`}
                            onClick={() => alterarMenuLateral(MenuLateralEnum.SOLUCIONADO)}
                            icone={faBoxArchive}
                            titulo={MenuLateralEnum.SOLUCIONADO}
                        />
                    </div>
                    <button
                        className="hover:cursor-pointer"
                        title={i18n.tit_desassociar_minhas_conversas}
                        onClick={clickDesassociarTodasConversas}>
                        <FontAwesomeIcon
                            icon={faChainSlash}
                            size="lg"
                            inverse
                        />
                    </button>
                </ul>
            </nav>
        </>
    );
}
