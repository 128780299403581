export enum StatusAtendimentoType {
    SOLUCIONADO = "SOLUCIONADO",
    ABERTO = "ABERTO",
}

export enum StatusLeituraEnum {
    LIDA = "LIDA",
    NOVA = "NOVA",
}

export type StatusLeituraType = "LIDA" | "NOVA";

export type StatusEnvioType = "ENVIADA" | "RECEBIDA";

export type ContatoType = {
    nome: string;
    telefone: string;
};
